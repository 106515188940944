<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="getFilters" :telecallers="telecallers" />
    <Statuses :filters="data_filters" />
  </Layout>
</template>

<script>
import Statuses from "./statuses";
import Layout from "../../../layouts/main";
import Filters from "./filters";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  name: "main-page",
  page: {
    title: "Best Call Status",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "",
      items: [],
      telecallers: [],
      data_filters: [],
    };
  },
  methods: {
    getFilters(e) {
      this.data_filters = e;
    },
    async getTelecallers() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/telecallers",
          {
            params: {
              status: true,
            },
          }
        );
        this.telecallers = response.data.telecallers;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getTelecallers();
  },
  components: {
    Statuses,
    Filters,
    Layout,
    PageHeader,
  },
};
</script>
