<script>
/**
 * Widget Component
 */

import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
import moment from 'moment';

export default {
  // props : ['telecallers'],
  components : {
    Multiselect,
    DatePicker
  },
  data() {
    return {
        filters : {
          project : null,
          startDate : null,
          endDate : null,
          productCallStatus : null,
          speciality : null,
          country : null,
          state : null,
          city : null,
          work_locations: null,
          opted_in : 0,
          daterange : null,
          telecaller : null,
          call_statuses : null
        },
        projects : [],
        specialities : [],
        states : [],
        cities : [],
        countries : ['India'],
        statuses : [],
        telecallers: [],
        workLocations: [],
        isHidden: false,
        clicked: false,
        hours : [],
    };
  },
  methods : {
    added() {
      this.clicked = !this.clicked;
      this.isHidden = !this.isHidden
    },
    async getTelecallersWithLocations(work_locations) {
      const response = await this.$http.get(appConfig.api_base_url + '/telecallers',{
        params : {
          work_locations : work_locations
        }
      });
      this.telecallers = response.data.telecallers;
    },
    async getProjects(){
        try{
          const response = await this.$http.get(appConfig.api_base_url + '/telecallers/projects');
          this.projects = response.data.telecaller_projects;
        } catch (err) {
          console.log(err);
        }
    },
    async getSpecialities(){
      try{
        const response = await this.$http.get(appConfig.api_base_url + '/specialities');
        this.specialities = response.data.specialities;
      } catch (err) {
        console.log(err);
      }
    },
    async loadFilters(){
      this.loadHours();
      this.getProjects();
      this.getSpecialities();
      this.getStates(this.countries);
      // this.getTelecallersWithLocations(this.work_locations);
      // this.getCallStatuses();
      // this.getWorkLocation();
    },
    async loadHours(){
      for (let index = 0; index < 23; index++) {
        this.hours.push({
          value : index,
          title : (index == 0 ? '12' : index) + ' - ' + (index + 1)
        });
      }
    },
    // getTimeSuffix(time){
    //   console.log(time);
    //   if(time >= 12){
    //     return 'PM'
    //   }
    //   return 'AM'
    // },
    async getStates(countries){
      try {
        const response = await this.$http.get(appConfig.api_base_url + '/geo/states',{
          params : {
            countries : countries
          }
        });
        this.states = response.data.states;
      }catch (err) {
        console.log(err);
      }
    },
    async getCities(states){
      try {
        const response = await this.$http.get(appConfig.api_base_url + '/geo/cities',{
          params : {
            states : states.map(obj => obj.name)
          }
        });
        this.cities = response.data.cities;
      }catch (err) {
        console.log(err);
      }
    },
    getFormatedDate(date){
        try {
          return moment(date).format('yyyy-MM-DD');
        }catch (err) {
          console.log(err);
          return null;
        }
    },
    async getCallStatuses(){
      try {
        if(this.$store.state.authfack.user.role != 'AnalyticsSuperAdmin') {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers/callSubStatuses"
          );
          this.statuses = response.data.call_statuses;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/telecallers/callStatuses"
          );
          this.statuses = response.data.call_statuses;
        }
      }catch (err) {
        console.log(err);
      }
    },
    getParams(params){
      let project_ids = this.filters.project ? this.filters.project.map(obj => obj.id) : {};
      if(project_ids && project_ids.length > 0){
        params.project_ids = project_ids;
      }
      let specialities = this.filters.speciality ? this.filters.speciality.map(obj => obj.title) : {};
      if(specialities && specialities.length > 0){
        params.specialities = specialities;
      }

      let opted_in = this.filters.opted_in ? this.filters.opted_in : null;
      if(opted_in && opted_in != null){
        params.opted_in = this.filters.opted_in ? this.filters.opted_in : false;
      }

      let from_date = this.filters.daterange && this.filters.daterange.length > 0 ? this.getFormatedDate(this.filters.daterange[0]) : null;
      if(from_date){
        params.from_date = from_date;
      }
      let to_date = this.filters.daterange && this.filters.daterange.length > 0 && this.filters.daterange[1] ? this.getFormatedDate(this.filters.daterange[1]) : null;
      if(to_date){
        params.to_date = to_date;
      }

      let states = this.filters.state ? this.filters.state.map(obj => obj.name) : {};
      if(states && states.length > 0){
        params.states = states;
      }

      let cities = this.filters.city ? this.filters.city.map(obj => obj.name) : {};
      if(cities && cities.length > 0){
        params.cities = cities;
      }

      let digimrs = this.filters.telecaller ? this.filters.telecaller.map(obj => obj.id) : {};
      if(digimrs && digimrs.length > 0){
        params.telecaller_ids = digimrs;
      }

      let statuses = this.filters.call_statuses ? this.filters.call_statuses.map(obj => obj.title) : {};
      if(statuses && statuses.length > 0){
        params.call_statuses = statuses;
      }
      
      let hours = this.filters.hours ? this.filters.hours : {};
      if(hours && hours.length > 0){
        params.hours = hours.map(f => f.value);
      }

      this.params = {};
      this.params = params;
      return params;
    },
  },
  mounted() {
    this.loadFilters();
  },
  watch :{
    filters : {
      deep : true,
      handler() {
        let params = this.getParams({});
        this.$emit('filters',params);
      }
    },
    'filters.state'(v){
      this.getCities(v);
    },
  }
};
</script>
<template>
  <div class="row">
    <div class="col-md-2">
        <div class="form-group">
            <label>Project</label>
            <multiselect v-model="filters.project" :show-labels="false" :close-on-select="false" :options="projects" track-by="id" label="title" :multiple="true"></multiselect>
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label>Speciality</label>
            <multiselect v-model="filters.speciality" :show-labels="false" :options="specialities" :close-on-select="false" track-by="title" label="title" :multiple="true"></multiselect>
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label>State</label>
            <multiselect v-model="filters.state" :show-labels="false" :options="states" :close-on-select="false" track-by="name" label="name" :multiple="true"></multiselect>
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group">
            <label>City</label>
            <multiselect v-model="filters.city" :show-labels="false" :options="cities" :close-on-select="false" track-by="name" label="name" :multiple="true"></multiselect>
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group mb-3">
            <label>Date Range</label>
            <date-picker v-model="filters.daterange" range append-to-body lang="en" confirm></date-picker>
        </div>
    </div>
  </div>
</template> 